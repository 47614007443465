import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import "./_referring-pages.scss";
import TEXT from "../../config/text";

import useTranslation from "../../hooks/useTranslation";

export default function TermsOfService() {
  const { appInfo } = useSelector((store) => store.globalState);
  const translation = useTranslation();

  const handleText = (text) => {
    if (translation?.pages?.terms_of_use?.[text]) {
      return translation?.pages?.terms_of_use?.[text];
    }
    return TEXT?.pages?.terms_of_use?.[text];
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
  return (
    <div className="referring-pages">
      <div
        style={{
          marginTop: "60px",
        }}
      >
        {/* <h1>Terms of use</h1>
        <p>
          We invite you to read these terms of {appInfo.app_name} company on{" "}
          {appInfo.app_name} site. The validation of your online transaction
          form is considered as an irrevocable acceptance of these terms.
        </p>
        <p> 1. Preamble </p>
        <p>
          The application {appInfo.app_name} Player is delivered without any
          content. In addition, no content is provided from the application even
          after activating it on our website. The information displayed at the
          beginning includes the MAC address as well as the website so that
          clients can activate the application once for life. The MAC address
          and the URL are essential for the proper functioning of the
          application. {appInfo.app_name} Player does not provide any content or
          subscription. The content and subscriptions provided under the{" "}
          {appInfo.app_name} Player app name are frauds. {appInfo.app_name}{" "}
          Player disclaims any responsibility for the use of fraudulent content.
          The application {appInfo.app_name} Player or it is entitled can not be
          held as responsible for the quality or interruptions of the content
          used on {appInfo.app_name}
          Player, {appInfo.app_name} Player only provides a media player. The
          application {appInfo.app_name}
          Player does not provide any advertising for content providers or
          subscriptions and reserves the right to sue anyone who uses our name
          or logo for advertising purposes.¸
        </p> */}

        <h1>{handleText("page_name")}</h1>
        <div dangerouslySetInnerHTML={{ __html: handleText("content") }} />

        {/* <p>{handleText("content")}</p> */}
      </div>
    </div>
  );
}
