import { useState } from "react";
import ValidateMacAddress from "../../components/ValidateMacAddress";
import style from "./style.module.scss";
import REQUESTS from "../../api/requests";
import { useSelector } from "react-redux";
import ConfirmDialouge from "./ConfirmPopUp";
import PATHS from "../../config/paths";
import { useNavigate } from "react-router-dom";
const TransferMacActivation = () => {
  const [device, setDevice] = useState(null);
  const [macIsValid, setMacIsValid] = useState("");
  const [macValue, setMacValue] = useState("");
  const [message, setMessage] = useState("");
  const [isDialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();

  const { deviceFromStore } = useSelector((state) => state.globalState);

  console.log(deviceFromStore, "deviceFromStore");

  const onFinish = () => {
    if (macIsValid) {
      console.log(macValue, "new mac");
      console.log(deviceFromStore.mac, "old mac");

      REQUESTS.UPDATE_DEVICE(
        { oldMac: deviceFromStore.mac, newMac: macValue },
        (data) => {
          setMessage("Mac address updated successfully");
          console.log(data, "data");
        },
        (err) => {
          setMessage("Error while transfer, try again later");

          console.log(err, "err");
        }
      );
      setTimeout(() => {
        const TOKEN = localStorage.getItem("TOKEN");
        if (TOKEN) {
          localStorage.removeItem("TOKEN");
          navigate(PATHS.MANAGE_DEVICES);
        }
      }, 4000);
    }
  };

  const handleSubmit = () => {
    if (macValue) setDialogOpen(true);
  };
  const handleConfirm = () => {
    setDialogOpen(false);
    onFinish();
  };
  return (
    <>
      <div className={style["page"]}>
        <h3 className={style["page-title"]}>Please read carefully</h3>
        <p className={style["warning-paragraph"]}>
          If you already activated device but mac address changed, then you can
          transfer activation to new device mac address, then current device
          would be deactivated, and new device would be activated
        </p>
        <ValidateMacAddress
          value={macValue}
          setMacIsValid={setMacIsValid}
          setDevice={setDevice}
          setValue={setMacValue}
        />
        {message && <div className={style["msg-container"]}>{message}</div>}
        <div className={style["submit-btn"]} onClick={handleSubmit}>
          <span>Submit</span>
        </div>

        <ConfirmDialouge
          isOpen={isDialogOpen}
          onClose={() => setDialogOpen(false)}
          onConfirm={handleConfirm}
        />
      </div>
    </>
  );
};

export default TransferMacActivation;
