import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { Layout } from "antd";

import Navbar from "./components/Navbar";
import FooterComponent from "./components/FooterComponent";

import style from "./style/index.module.scss";

export default function LayoutComponent() {
  const { webSettings, widgets } = useSelector((store) => store.globalState);

  return (
    <Layout>
      <Layout.Header className={style.header}>
        <Navbar />
      </Layout.Header>

      <Layout.Content>
        <Outlet />
        <div className={style["social-links-container"]}>
          {webSettings &&
            webSettings.footer &&
            webSettings.footer.social_links &&
            webSettings.footer.social_links.map((item, index) => {
              const { link, icon, id, fast_support, name, enabled } = item;
              if (fast_support && enabled) {
                return (
                  <div className={style["social-link"]} key={id}>
                    <a
                      href={name === "Email" ? `mailto:${link}` : link}
                      target="_blank"
                    >
                      <img src={icon} alt="social link" />
                    </a>
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>
      </Layout.Content>
      <Layout.Footer className={style.footer}>
        <FooterComponent />
      </Layout.Footer>
    </Layout>
  );
}
