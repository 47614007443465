import { useEffect, useState } from "react";
import PageComponent from "../../components/PageComponent";
import "./_referring-pages.scss";
import privacyImg from "./privacy.gif";

export default function PrivacyPolicyPage() {
  // const handleText = (text) => {
  //   if (translation?.pages?.privacy?.[text]) {
  //     return translation?.pages?.privacy?.[text];
  //   }
  //   return TEXT?.pages?.privacy?.[text];
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PageComponent>
      <div className="referring-pages privacy">
        <div className="content-holder">
          <h1 className="privacy-title">PRIVACY POLICY</h1>
          <p>
            Homeplayer.app built the Home Player app as a Commercial app. This
            SERVICE is provided by Home Player and is intended for use as is.
            This page is used to inform visitors regarding our policies with the
            collection, use, and disclosure of Personal Information if anyone
            decides to use our Service. If you choose to use our Service, then
            you agree to the collection and use of information in relation to
            this policy. The Personal Information that we collect is used for
            providing and improving the Service. We will not use or share your
            information with anyone except as described in this Privacy Policy.
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which are accessible at
            https://homeplayer.app unless otherwise defined in this Privacy
            Policy. Information Collection and Use For a better experience,
            while using our Service, we may require you to provide us with
            certain personally identifiable information, including but not
            limited to SmartTV MAC address, email, first name, last name,
            address, and phone number. The information that we request will be
            retained by us and used as described in this privacy policy. The app
            does use third-party services that may collect information used to
            <span>identify you.</span>
          </p>
          {!isExpanded && (
            <>
              <button className="read-more-btn" onClick={toggleReadMore}>
                Read More
              </button>
            </>
          )}
          {isExpanded && (
            <>
              <h3>Log Data</h3>
              <p>
                We want to inform you that whenever you use our Service, in the
                case of an error in the app, we collect data and information
                (through third-party products) on your SmartTV called Log Data.
                This Log Data may include information such as your device
                Internet Protocol (IP) address, MAC address, device name,
                operating system version, the configuration of the app when
                utilizing our Service, the time and date of your use of the
                Service, and other statistics.
              </p>
              <h3>Cookies</h3>
              <p>
                Cookies are files with a small amount of data that are commonly
                used as anonymous unique identifiers. These are sent to your
                browser from the websites that you visit and are stored on your
                device's internal memory. This Service does not use these
                cookies explicitly. However, the app may use third-party code
                and libraries that use cookies to collect information and
                improve their services. You have the option to either accept or
                refuse these cookies and know when a cookie is being sent to
                your device. If you choose to refuse our cookies, you may not be
                able to use some portions of this Service.
              </p>
              <h3>Service Providers</h3>
              <p>
                We may employ third-party companies and individuals for the
                following reasons: To facilitate our Service; To provide the
                Service on our behalf; To perform Service-related services; or
                To assist us in analyzing how our Service is used. We want to
                inform users of this Service that these third parties have
                access to your Personal Information. The reason is to perform
                the tasks assigned to them on our behalf. However, they are
                obligated not to disclose or use the information for any other
                purpose.
              </p>
              <h3>Security</h3>
              <p>
                We value your trust in providing us with your Personal
                Information, thus we are striving to use commercially acceptable
                means of protecting it. But remember that no method of
                transmission over the internet or method of electronic storage
                is 100% secure and reliable, and we cannot guarantee its
                absolute security.
              </p>
              <h3> Links to Other Sites</h3>
              <p>
                This Service may contain links to other sites. If you click on a
                third-party link, you will be directed to that site. Note that
                these external sites are not operated by us. Therefore, we
                strongly advise you to review the Privacy Policy of these
                websites. We have no control over and assume no responsibility
                for the content, privacy policies, or practices of any
                third-party sites or services.
              </p>
              <h3>Changes to This Privacy Policy</h3>
              <p>
                We may update our Privacy Policy from time to time. Thus, you
                are advised to review this page periodically for any changes. We
                will notify you of any changes by posting the new Privacy Policy
                on this page. These changes are effective immediately after they
                are posted on this page.
              </p>
              <h3> Contact Us</h3>
              <p>
                If you have any questions or suggestions about our Privacy
                Policy, do not hesitate to contact us at support@homeplayer.app.
              </p>
            </>
          )}
        </div>
        <div className="img-holder">
          <img src={privacyImg} alt="privacy" />
        </div>
      </div>
    </PageComponent>
  );
}
