// src\App.js
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "./store";

import "antd/dist/antd.css";

import REQUESTS from "./api/requests";

import PATHS from "./config/paths";

import HomePage from "./pages/home/index.js";
import ActivationPage from "./pages/activationPage/ActivationPage.jsx";
import Reseller from "./pages/reseller/Reseller.jsx";
import NewsPage from "./pages/news/NewsPage.jsx";
import ContactUs from "./pages/contact/ContactUs.jsx";
import LayoutComponent from "./layout";
import CurrentNews from "./pages/news/CurrentNews.jsx";
import LoginByMac from "./pages/login-by-mac/LoginByMac";

import PrivacyPolicy from "./pages/referring-pages/PrivacyPolicy.jsx";
import UploadPlaylistPage from "./pages/upload-playlist/UploadPlaylistPage.jsx";
import MoreInfoAboutPartner from "./pages/referring-pages/MoreInfoAboutPartner.jsx";
import PaymentSuccessPage from "./pages/activationPage/PaymentSuccessPage.jsx";
import PaymentErrorPage from "./pages/activationPage/PaymentErrorPage.jsx";
import Chat from "./pages/real-time-chat/Chat.jsx";

import "./index.scss";
import TermsOfService from "./pages/referring-pages/TermsOfService";
import web_settings from "./config/web_settings";
import NotFound from "./pages/notFoundPage/NotFound";
import UnderConstruction from "./pages/constructionPage/UnderConstruction";
import InstallationGuide from "./pages/installationGuide/InstallationGuide";
import SimpleActivationPage from "./pages/activationPage/SimpleActivationPage";
import ActivationByVoucherCode from "./pages/activateByVoucherCode/ActivationByVoucherCode";
import ManagePlaylistLayout from "./pages/managePlaylist/layout/ManagePlaylistLayout";
import MyProfile from "./pages/managePlaylist/components/MyProfile";
import MyPlaylists from "./pages/managePlaylist/components/MyPlaylists";
import ManageCategories from "./pages/managePlaylist/components/ManageCategories";
import EmailRegistration from "./pages/multipleDeviceRegister/components/EmailRegistration";
import PasswordSetup from "./pages/multipleDeviceRegister/components/PasswordSetup";
import MultipleDeviceLogin from "./pages/multipleDeviceRegister/MultipleDeviceLogin";
import Devices from "./pages/multipleDeviceRegister/components/Devices";
import PricingPage from "./pages/pricing/PricingPage";
import ManageDevicesTabs from "./pages/manageDevicesTabs/ManageDevicesTabs.jsx";
import ProtectedRoute from "./layout/components/ProtectedRoute.js";
import TermsOfUse from "./pages/referring-pages/TermsOfUse.jsx";
import ActivateDeviceModal from "./pages/activationPage/ActivateDeviceModal.jsx";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAdmin = useSelector((store) => store.globalState.isAdmin);
  const { widgets } = useSelector((state) => state.globalState);

  const { setWebSettings } = bindActionCreators(actions, dispatch);
  const { setAdmin } = bindActionCreators(actions, dispatch);
  const { setWidgets } = bindActionCreators(actions, dispatch);
  const [isLoading, setIsLoading] = useState(true);

  const configWebSettings = (data) => {
    const root = document.documentElement;
    root.style.setProperty("--active", data.globals.active_color);
    root.style.setProperty("--background-color", data.globals.background_color);
    root.style.setProperty(
      "--button-text-color",
      data.globals.buttons_text_color
    );
    root.style.setProperty("--hover-color", data.globals.hover_color);
    root.style.setProperty("--text-color", data.globals.text_color);
    root.style.setProperty("--title-color", data.globals.title_color);
    root.style.setProperty("--logo-size", `${data.globals.logo_size}px`);
    root.style.setProperty(
      "--navbar-background-color",
      data.navbar.background_color
    );
    root.style.setProperty("--navbar-text-color", data.navbar.text_color);

    createInputStyle(data.globals.inputBorderStyle);
  };

  const createInputStyle = (style) => {
    if (document.getElementById("input-style-css")) {
      document.getElementById("input-style-css").remove();
    }

    const styleElement = document.createElement("link");

    styleElement.id = "input-style-css";
    styleElement.rel = "stylesheet";

    if (style === "rectangle") {
      styleElement.href = "./rectangleInput.css";
    } else {
      styleElement.href = "./underlineInput.css";
    }

    document.head.appendChild(styleElement);
  };

  const getEditorConfigs = () => {
    setIsLoading(true);

    REQUESTS.GET_EDITOR_CONFIGS(
      (data) => {
        setWebSettings(JSON.parse(data.json_configs));

        configWebSettings(JSON.parse(data.json_configs));
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const getWidgets = () => {
    REQUESTS.GET_WIDGETS(
      (data) => {
        setWidgets(data);
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(true);
      }
    );
  };

  useEffect(() => {
    getWidgets();

    configWebSettings(web_settings);

    getEditorConfigs();

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    if (token != null) {
      sessionStorage.setItem("TOKEN", token);
    }

    if (sessionStorage.getItem("TOKEN")) {
      REQUESTS.VALIDATE_TOKEN(
        {},
        () => {
          setAdmin(true);
        },
        () => {
          setAdmin(false);
          sessionStorage.removeItem("TOKEN");
        },
        token
      );
      // }
    } else {
      setAdmin(false);
    }
  }, []);

  return (
    <div className="App">
      {isLoading && <div className="full-screen-loading"></div>}
      <Routes>
        <Route path="/" element={<LayoutComponent />}>
          <Route
            path={PATHS.UNDER_CONSTRUCTION}
            element={<UnderConstruction />}
          />
          <Route
            path={PATHS.ACTIVATE_DEVICE}
            element={<ActivateDeviceModal />}
          />
          <Route path={PATHS.HOME} element={<HomePage />} />
          <Route path="/" element={<Navigate replace to="home" />} />
          {widgets &&
            widgets.hide_activation_page &&
            widgets.hide_activation_page.enabled &&
            widgets.hide_activation_page.enabled === "false" && (
              <>
                <Route
                  path="activation"
                  element={
                    widgets &&
                    widgets.simple_activation_page &&
                    widgets.simple_activation_page.enabled &&
                    widgets.simple_activation_page.enabled === "true" ? (
                      <SimpleActivationPage />
                    ) : (
                      <ActivationPage />
                    )
                  }
                />
                <Route path="activation" element={<ActivationPage />} />
              </>
            )}
          {widgets &&
            widgets.simple_activation_page &&
            widgets.simple_activation_page.enabled &&
            widgets.simple_activation_page.enabled === "true" && (
              <Route path="activation" element={<SimpleActivationPage />} />
            )}
          {widgets &&
            widgets.hide_upload_playlist &&
            widgets.hide_upload_playlist.enabled &&
            widgets.hide_upload_playlist.enabled === "false" && (
              <>
                <Route
                  path={PATHS.UPLOAD_PLAYLIST}
                  element={<UploadPlaylistPage />}
                />
                <Route path={"autologin"} element={<UploadPlaylistPage />} />
              </>
            )}
          {widgets?.referral?.enabled === "true" &&
            widgets?.reseller?.enabled === "true" && (
              <Route path="reseller" element={<Reseller />} />
            )}
          {widgets?.news?.enabled === "true" && (
            <>
              <Route path={PATHS.NEWS} element={<NewsPage />} />
              <Route path="news/:id" element={<CurrentNews />} />
            </>
          )}
          {widgets?.pricing?.enabled === "true" && (
            <Route path={PATHS.PRICING} element={<PricingPage />} />
          )}
          {widgets?.support?.enabled === "true" && (
            <Route path={PATHS.CONTACT_US} element={<ContactUs />} />
          )}
          {widgets &&
            widgets.installation_guide &&
            widgets.installation_guide.enabled &&
            widgets.installation_guide.enabled === "true" && (
              <Route
                path={PATHS.INTALLATION_GUIDE}
                element={<InstallationGuide />}
              />
            )}
          {widgets?.chat?.enabled === "true" && (
            <Route path="chat" element={<Chat />} />
          )}
          {isAdmin && widgets?.support?.enabled === "true" && (
            <Route path="chat" element={<Chat />} />
          )}

          {/* new */}
          <Route
            path={PATHS.MANAGE_DEVICES}
            element={
              <ProtectedRoute
                element={<LoginByMac />}
                redirectTo={PATHS.MANAGE_TABS}
                checkToken={false}
              />
            }
          />
          <Route
            path={PATHS.MANAGE_TABS}
            element={
              <ProtectedRoute
                element={<ManageDevicesTabs />}
                redirectTo={PATHS.MANAGE_DEVICES}
                checkToken={true}
              />
            }
          />
          {/* new */}
          <Route path="info-link" element={<MoreInfoAboutPartner />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
          <Route path="payment-success" element={<PaymentSuccessPage />} />
          {/* new */}
          <Route path="payment-error" element={<PaymentErrorPage />} />
          <Route path={PATHS.TERMS_OF_USE} element={<TermsOfService />} />
          <Route
            path={PATHS.VOUCHER_CODE}
            element={<ActivationByVoucherCode />}
          />
          {widgets?.multiple_device_register?.enabled === "true" && (
            <>
              <Route
                path={PATHS.EMAIL_REGISTRATION}
                element={<EmailRegistration />}
              />
              <Route path={PATHS.PASSWORD_SETUP} element={<PasswordSetup />} />
              <Route
                path={PATHS.MULTIPLE_DEVICE_LOGIN}
                element={<MultipleDeviceLogin />}
              />
              <Route path={PATHS.DEVICES} element={<Devices />} />
            </>
          )}
          <Route path="*" element={<NotFound />} />
        </Route>

        {
          <Route
            path={PATHS.MANAGE_PLAYLIST}
            element={<ManagePlaylistLayout />}
          >
            <Route index path="profile" element={<MyProfile />} />
            <Route path="playlists" element={<MyPlaylists />} />
            <Route path="categories" element={<ManageCategories />} />
          </Route>
        }
      </Routes>
    </div>
  );
}

export default App;
