import React, { useEffect, useState } from "react";
import { Form } from "antd";
import useTranslation from "../../hooks/useTranslation";
import REQUESTS from "../../api/requests";
import style from "./style/payment.module.scss";
import TEXT from "../../config/text";
import { useSelector } from "react-redux";
import whatsappImg from "./social.png";

export default function DirectActivation() {
  const translation = useTranslation();
  const { deviceFromStore } = useSelector((store) => store.globalState);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(""); // Track selected package

  const [form] = Form.useForm();

  const handleText = (text) => {
    if (translation?.pages?.activation?.[text]) {
      return translation?.pages?.activation?.[text];
    }
    return TEXT?.pages?.activation?.[text];
  };

  const getPackages = () => {
    const callback = (message) => {
      setPackages(message.rows);
    };

    const errorCallback = (error) => {
      console.log(error, "error getting packages");
    };

    REQUESTS.GET_PACKAGES(callback, errorCallback);
  };

  // Get last selected package from localStorage on component mount
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    getPackages();

    const lastSelectedPackage = localStorage.getItem("selectedPackage");
    if (lastSelectedPackage) {
      setSelectedPackage(lastSelectedPackage);
    }
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handlePackageSelection = (pkgName) => {
    setSelectedPackage(pkgName);
    setDropdownOpen(false);
    localStorage.setItem("selectedPackage", pkgName);
  };

  const handleWhatsAppRedirect = () => {
    const phoneNumber = "15867652233";
    const message = encodeURIComponent("Hi, I'm interested in your packages.");
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <div className={style["page-activation"]}>
      {deviceFromStore && deviceFromStore.payed ? (
        <div className={style["activated-container"]}>
          <p className={style["activated-p"]}>
            This device has already an activated package.
          </p>
        </div>
      ) : (
        <>
          <Form
            autoComplete="off"
            name="activation"
            form={form}
            className="activation-form-style"
            layout="vertical"
          >
            <p className={style["text"]}>{handleText("choose_plan_text")}</p>
            <button
              id="dropdownInformationButton"
              onClick={toggleDropdown}
              className="text-white bg-blue-700 hover:bg-blue-800 mt-4 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="button"
            >
              {/* Display the selected package name */}
              {selectedPackage || "Choose package"}
              <svg
                className="w-2.5 h-2.5 ms-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>
            {dropdownOpen && (
              <div
                id="dropdownInformation"
                className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
              >
                <ul className="py-2 text-sm text-gray-700 w-full dark:text-gray-200">
                  {packages.length > 0 &&
                    packages.map((pkg) => (
                      <li key={pkg.id}>
                        <button
                          onClick={() => handlePackageSelection(pkg.name)}
                          className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          {pkg.name}
                        </button>
                      </li>
                    ))}
                </ul>
              </div>
            )}
            <div className="mt-6 flex justify-center items-center ">
              <button
                onClick={handleWhatsAppRedirect}
                className="text-black  hover:text-green-200 focus:ring-4  font-medium rounded-lg text-sm px-3 py-2 mt-3 text-center inline-flex items-center transition-colors duration-300"
                type="button"
              >
                Connect to WhatsApp
                <img
                  src={whatsappImg}
                  alt="whatsapp"
                  style={{
                    width: "30px",
                    height: "30px",
                    marginLeft: "0.5rem",
                  }}
                />
              </button>
            </div>
          </Form>
        </>
      )}
    </div>
  );
}
