import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { Dropdown, Menu } from "antd";

import { actions } from "../../store";

import REQUESTS from "../../api/requests";

import style from "../style/language.module.scss";

/**
 * Designed to translate languages, translation is managed from the admin panel
 */

export default function Languages() {
  const dispatch = useDispatch();

  const { setSelectedLanguage } = bindActionCreators(actions, dispatch);

  const [languages, setLanguages] = useState([]);
  const [isActive, setIsActive] = useState(null);

  const getLanguages = () => {
    function callback(data) {
      setLanguages(data);

      const languageIdFromLocalStorage = localStorage.getItem("LANGUAGE_ID");

      if (languageIdFromLocalStorage) {
        const active = data.find(
          (item) => item.id == languageIdFromLocalStorage
        );

        if (active) {
          setSelectedLanguage(active);
          setIsActive(active);
        } else {
          const activeLanguage = data.find((item) => item.is_default);
          setIsActive(activeLanguage);
          setSelectedLanguage(activeLanguage);
        }
      } else {
        const active = data.find((item) => item.is_default);

        setIsActive(active);

        setSelectedLanguage(active);
      }
    }

    REQUESTS.LANGUAGES(callback);
  };

  const onChangeLanguage = (selected) => {
    if (languages) {
      setSelectedLanguage(selected);
      setIsActive(languages.find((item) => item.id === selected.id));
    }
  };

  useEffect(() => {
    if (isActive) {
      localStorage.setItem("LANGUAGE_ID", isActive.id);
      localStorage.setItem("LANGUAGE_CODE", isActive.iso_code || "en");
    }
  }, [isActive]);

  const menu = (
    <Menu className={style["languages-dropdown"]}>
      {languages.map((item) => {
        return (
          <Menu.Item key={item.id} onClick={() => onChangeLanguage(item)}>
            <img src={item.icon} alt="" />
            <span>{item.name}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  useEffect(() => {
    getLanguages();
  }, []);

  return (
    <Dropdown overlay={menu}>
      <div className={style["languages-block"]}>
        <img src={isActive?.icon} alt="" />
        <span className={style.language}>{isActive?.name} </span>
      </div>
    </Dropdown>
  );
}
