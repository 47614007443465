// src\pages\contact\ContactUsInfo.jsx
import TEXT from "../../config/text";
import useTranslation from "../../hooks/useTranslation";

import style from "./style/contact.module.scss";

export default function ContactUsInfo() {
  const translation = useTranslation();

  const handleText = (text) => {
    if (translation?.pages?.contact_us?.[text]) {
      return translation?.pages?.contact_us?.[text];
    }

    return TEXT?.pages?.contact_us?.[text];
  };

  return (
    <div className={style["contact-us_info"]}>
      <div className={style["info"]}>
        <h1 className={style["title"]}>{handleText("title")}</h1>
      </div>
      <p className={style["description"]}>{handleText("description")}</p>
    </div>
  );
}
