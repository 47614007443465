import React from "react";

import PageComponent from "../../components/PageComponent";

import "./_referring-pages.scss";

export default function MoreInfoAboutPartner() {
  return (
    <PageComponent>
      <div className="referring-pages">
        <div>
          <h1> Let’s work together</h1>
          <p>
            A flexible, value-based program provides you with competitive prices
            that will help you sell our app and make gains you want.
          </p>
        </div>

        <div>
          <h1>Partnership goals</h1>
          <p>
            Joining our program means being part of our Team. We will take care
            of all your business needs in terms of support for the app as we
            fully understand that It takes more than a great product to grow
            your business—which means we rely on our partners just as much as
            they rely on us. Whether you’re looking to create new revenue
            opportunities with existing clients, acquire new customers by
            combining our app with your playlist sale for an all in one package,
            or all of the above, we’ve got the tools you need to make it happen.
          </p>
        </div>

        <div>
          <h1>Find the right fit for you</h1>
          <p>
            No two businesses are the same, which is why our program is based on
            capabilities and contributions. Get your business in shape by
            choosing the option that works for you—we’ll spot you from there.
          </p>
        </div>

        <div>
          <h1>Reseller program</h1>
          <p>
            You will be able to purchase the app at a competitive price which
            will go lower as you advance in tears and are able to sell it at the
            price that you want. You also benefit from support to your customers
            and have full control over the devices you activate.
          </p>
        </div>

        <div>
          <h1>Affiliate program</h1>
          <p>
            Everything you need to get up and running without an initial
            investment. With this program we would like to give the opportunity
            for our partners to start with no initial investment. You’ll be able
            to generate links that you could set for different acquisition
            platforms and track your stats as well have a revenue stream just by
            focusing on marketing.
          </p>
        </div>
      </div>
    </PageComponent>
  );
}
