import { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import style from "./style/home.module.scss";
import image1 from "../../assets/app/theme3.png";
import image3 from "../../assets/app/liveTv.png";
import image4 from "../../assets/app/movie.png";
import image5 from "../../assets/app/serie.png";
import image6 from "../../assets/app/language.png";
import image7 from "../../assets/app/add-playlist.png";
import image8 from "../../assets/app/playlists.png";
import image9 from "../../assets/app/manage-channels.png";
import useTranslation from "../../hooks/useTranslation";

const featuresData = [
  [
    "Easy to navigate.",
    "Better UI for a good user experience.",
    "Attractive design.",
  ],
  [
    "Live TV Page.",
    "Add favourite channels.",
    "Search for channels.",
    "Add group.",
    "EPG list.",
    "Manage channels.",
    "Move channel position.",
    "Hide / Lock Channels.",
  ], // Features for slide 2
  [
    "Categories List",
    "Option ( Sort / Lock / Hide ) Categories",
  ],
  ["Movie Information", "Movie Poster", "Play / Add to favourites.", "Subtitle support", "Movie length"], // Features for slide 4
  ["Series Information", "Series Poster.", "Play / Add to favourites", "Subtitle support", "Number of episodes"], // Features for slide 5
  ["Language support", "Multiple languages", "Easy to switch"], // Features for slide 6
  [
    "Manage playlists",
    "select playlist",
    "remove playlist",
    "Add new Playlist.",
  ], // Features for slide 9
  ["Add playlist", "Organize content", "Simple management"], // Features for slide 8
];

export default function HomePage() {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {}, [activeIndex]);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <div dir="auto" className={style["main-h"]}>
      <div className={style["main-hero"]}>
        {/* Carousel */}
        <Carousel
          data-bs-theme="light"
          indicators={false}
          pause={false}
          activeIndex={activeIndex}
          onSelect={handleSelect}
          className="carousel"
        >
          <Carousel.Item className={style["item-c"]}>
            <img className="d-block w-100" src={image1} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item className={style["item-c"]}>
            <img className="d-block w-100" src={image3} alt="Third slide" />
          </Carousel.Item>
          <Carousel.Item className={style["item-c"]}>
            <img className="d-block w-100" src={image9} alt="Fourth slide" />
          </Carousel.Item>
          <Carousel.Item className={style["item-c"]}>
            <img className="d-block w-100" src={image4} alt="Fifth slide" />
          </Carousel.Item>
          <Carousel.Item className={style["item-c"]}>
            <img className="d-block w-100" src={image5} alt="Sixth slide" />
          </Carousel.Item>
          <Carousel.Item className={style["item-c"]}>
            <img className="d-block w-100" src={image6} alt="Seventh slide" />
          </Carousel.Item>
          <Carousel.Item className={style["item-c"]}>
            <img className="d-block w-100" src={image8} alt="Ninth slide" />
          </Carousel.Item>
          <Carousel.Item className={style["item-c"]}>
            <img className="d-block w-100" src={image7} alt="Eighth slide" />
          </Carousel.Item>
        </Carousel>
        {/* </div> */}

        {/* Content */}
        <div className={style["content-holder"]}>
          <h1 className={style["hero-title"]}>Welcome To Home Player</h1>
          <p className={style["hero-p"]}>
            Home Player is the most easy-to-use and user-friendly TV media
            player. With Home Player, you will enjoy your favorite media content
            on your TV.
          </p>
          <p className={style["feature"]}>Features:</p>
          {/* Dynamic features */}
          <ul className={style["features-list"]}>
            {featuresData[activeIndex].map((feature, index) => (
              <li className={style["features-item"]} key={index}>
                {feature}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
