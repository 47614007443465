import { Tabs } from "antd";
import { useSelector } from "react-redux";
import "./_style.scss";
import ActivationEditor from "../../editors/ActivationEditor";
import DirectActivation from "./DirectActivation";
import CouponActivation from "./CouponActivation";

import style from "./style/payment.module.scss";
import TEXT from "../../config/text";
import useTranslation from "../../hooks/useTranslation";

/**
 *  The page is meant for MAC address-based device activation.
 * @returns
 */

export default function ActivationPage() {
  //   const navigate = useNavigate();
  const translation = useTranslation();
  const { webSettings } = useSelector((state) => state.globalState);

  const { isAdmin } = useSelector((store) => store.globalState);

  const { background_color, background_image } = webSettings?.pages?.activation;

  const pageStyle = {
    backgroundColor: background_color,
    backgroundImage: `url(${background_image})`,
    backgroundSize: "cover",
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    margin: 0,
  };

  const handleText = (text) => {
    if (translation?.pages?.activation?.[text]) {
      return translation?.pages?.activation?.[text];
    }

    return TEXT?.pages?.activation?.[text];
  };

  return (
    <div className="page-wrapper-tab" style={pageStyle}>
      {isAdmin && <ActivationEditor />}
      <div className={style["page-container"]}>
        <Tabs
          centered={true}
          tabBarStyle={{
            color: "black",
            margin: "auto",
            width: "350px",
          }}
          destroyInactiveTabPane
        >
          <Tabs.TabPane key="1" tab={"Plan"}>
            <DirectActivation />
          </Tabs.TabPane>
          {/* COUPON */}
          {/* {widgets?.coupons?.enabled === "true" && (
            <Tabs.TabPane tab={handleText("gift_code")} key="2">
              <CouponActivation />
            </Tabs.TabPane>
          )} */}
        </Tabs>
      </div>
    </div>
  );
}
