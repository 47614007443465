import React, { useEffect, useState } from "react";
import EditPlaylist from "./EditPlaylist";
import { ICONS } from "../../config/icons";
import DeletePlaylistModal from "./DeletePlaylistModal";

import style from "./style/style.module.scss";
import EnterPinModal from "./EnterPinModal";
import REQUESTS from "../../api/requests";
import ErrorModal from "../../components/ErrorModal";

export default function Playlist({ playlist, getPlaylists, deviceId }) {
  const [openDelteModal, setOpenDeleteModal] = useState(false);

  const [protectedPlaylist, setProtectedPlaylist] = useState(null);

  const [visibleEditPlaylistModal, setVisibleEditPlaylistModal] =
    useState(false);

  const [openPinModal, setOpenPinModal] = useState(false);
  const [actionType, setActionType] = useState("edit");
  const [pin, setPin] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const confirmProtectedPlaylistPin = (values) => {
    const { pin } = values;

    setPin(pin);

    const query = { id: playlist.id, pin };

    setErrorMessage(null);

    function callback(data) {
      setProtectedPlaylist(data);
      setOpenPinModal(false);

      switch (actionType) {
        case "edit":
          setVisibleEditPlaylistModal(true);
          break;
        case "delete":
          setOpenDeleteModal(true);
          break;

        default:
          break;
      }
    }

    function errorCallback(error) {
      setProtectedPlaylist(null);
      setErrorMessage(error.message);
    }

    REQUESTS.PROTECTED_PLAYLIST(query, callback, errorCallback);
  };

  useEffect(() => {
    if (errorMessage) {
      setOpenPinModal(false);
    }
  }, [errorMessage]);

  return (
    <div>
      <div className={style["single-playlist"]}>
        <div className={style["single-playlist__name-url"]}>
          <div className={style["name"]}>{playlist?.name}</div>
          <div className={style["url"]}>
            {!playlist["is_protected"]
              ? playlist.url
              : "The playlist is protected"}
          </div>
        </div>

        <div className={style["edit-delete"]}>
          <div
            className={style["edit"]}
            onClick={() => {
              if (playlist["is_protected"]) {
                setOpenPinModal(true);
                setActionType("edit");
              } else {
                setVisibleEditPlaylistModal(true);
              }
            }}
          >
            {ICONS.PLAYLIST_EDIT}
          </div>
          <div
            className={style["delete"]}
            onClick={() => {
              if (playlist["is_protected"]) {
                setOpenPinModal(true);
                setActionType("delete");
              } else {
                setOpenDeleteModal(true);
              }
            }}
          >
            {ICONS.DELETE}
          </div>
        </div>
      </div>

      {playlist["is_protected"] && openPinModal && (
        <EnterPinModal
          open={openPinModal}
          hideModal={() => {
            setOpenPinModal(false);
          }}
          confirmProtectedPlaylistPin={confirmProtectedPlaylistPin}
        />
      )}

      <DeletePlaylistModal
        open={openDelteModal}
        setOpen={setOpenDeleteModal}
        getPlaylists={getPlaylists}
        playlist={playlist}
        pin={pin}
      />
      <EditPlaylist
        visible={visibleEditPlaylistModal}
        onCancel={() => setVisibleEditPlaylistModal(false)}
        getPlaylists={getPlaylists}
        deviceId={deviceId}
        playlist={playlist}
        protectedPlaylist={protectedPlaylist}
        setProtectedPlaylist={setProtectedPlaylist}
        pin={pin}
      />
      <ErrorModal
        message={errorMessage}
        visible={errorMessage ? true : false}
        onClose={() => setErrorMessage(null)}
      />
    </div>
  );
}
