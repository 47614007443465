import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { Typography } from "antd";

import useTranslation from "../../hooks/useTranslation";

import TEXT from "../../config/text";
import style from "../style/footer.module.scss";

const FooterComponent = () => {
  const translation = useTranslation();

  const { webSettings } = useSelector((store) => store.globalState);

  const footerStyle = {};

  const handleText = (page, text) => {
    if (translation?.pages?.[page]?.[text]) {
      return translation?.pages?.[page]?.[text];
    }

    return TEXT?.pages?.[page]?.[text];
  };

  return (
    <div className={style["footer"]} style={footerStyle}>
      <div className={style["copyright-wrapper"]}>
        <Typography.Text className={style["reserved"]}>
          {webSettings?.footer?.reserved?.enabled ? (
            <a href={webSettings?.footer?.reserved?.link} target="_blanck">
              {webSettings?.footer?.reserved?.name &&
                `${webSettings?.footer?.reserved?.name} ©`}
            </a>
          ) : (
            <a href="!#">HomePlayer ©</a>
          )}
          {new Date().getFullYear()} |{" "}
          {translation?.general?.all_rights_reserved ||
            TEXT?.general?.all_rights_reserved}
        </Typography.Text>
      </div>
      <div className={style["terms-container"]}>
        <Link className={style["footer-link"]} to="/privacy-policy">
          Privacy Policy
        </Link>
        <Link className={style["footer-link"]} to="/terms-of-use">
          Terms Of Use
        </Link>
      </div>
    </div>
  );
};

export default FooterComponent;
