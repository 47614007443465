import React, { useState, useEffect } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { useSelector } from "react-redux";

import { Form, Input } from "antd";

import useTranslation from "../../hooks/useTranslation";
import settingIcon from "../../assets/setting.png";
import ValidateMacAddress from "../../components/ValidateMacAddress";

import REQUESTS from "../../api/requests";

import TEXT from "../../config/text";

import ErrorModal from "../../components/ErrorModal";

import styles from "./style/style.module.scss";

export default function LoginByMac() {
  const [value, setValue] = useState("");

  const [searchParams] = useSearchParams();

  const [macIsValid, setMacIsValid] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const [visible, setVisible] = useState(false);

  const [device, setDevice] = useState(null);

  const translation = useTranslation();

  const widgets = useSelector((state) => state.globalState.widgets);

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const handleText = (text) => {
    if (translation?.pages?.login?.[text]) {
      return translation?.pages?.login?.[text];
    }
    return TEXT?.pages?.login?.[text];
  };

  const getOTP = () => {
    const body = { mac: value };

    function callback(data) {}

    REQUESTS.GET_OTP(callback, body);
  };

  const onFinish = (values) => {
    setErrorMessage(null);
    const { code } = values;

    const body = {
      mac: value,
      // code,
    };

    if (device && device["auth_type"] === "device_key") {
      body.key = code.trim();
    } else if (device) {
      body.code = code.trim();
    }

    REQUESTS.LOGIN_BY_MAC(
      (data) => {
        localStorage.setItem("TOKEN", data);

        if (widgets?.user_login2?.enabled === "true") {
          navigate("/manage-devices");
        } else {
          navigate("/manage-tabs");
        }
      },
      (err) => {
        console.log(err, "test");
        setErrorMessage(err.message);
      },
      body
    );
    // form.submit();
  };

  useEffect(() => {
    if (visible && device && device["auth_type"] === "otp") {
      getOTP();
    }

    if (!visible) {
      form.setFieldValue("code", "");
    }
  }, [visible]);

  useEffect(() => {
    if (searchParams.get("mac")) {
      setValue(searchParams.get("mac"));
    }
  }, []);
  return (
    <>
      <div className={styles["page"]}>
        <div className={styles["title-container"]}>
          <h1 className={styles["manage-device-title"]}>
            <span className={styles["special-word"]}>Manage</span> your devices
          </h1>
          <h4 className={styles["title-main-p"]}>Login to your device</h4>
          <div className={styles["setting-border-container"]}>
            <span className={styles["border-left"]}></span>
            <img src={settingIcon} alt="icon" />
            <span className={styles["border-right"]}></span>
          </div>
        </div>
        {/* login */}
        <div className={styles["login-container"]}>
          <h1 className={styles["login-container-title"]}>
            <span className={styles["special-word"]}>device</span> login
          </h1>
          <div className={styles["input-container"]}>
            <Form
              autoComplete="off"
              name="login"
              form={form}
              onFinish={onFinish}
            >
              <>
                <div className={styles["input-container-inner"]}>
                  <ValidateMacAddress
                    setMacIsValid={setMacIsValid}
                    value={value}
                    setValue={setValue}
                    setDevice={setDevice}
                    isLogin={true}
                  />
                  <Form.Item
                    name="code"
                    rules={[
                      {
                        required: true,
                        message:
                          device &&
                          device["auth_type"] === "device_key" &&
                          handleText("device_key_input_message"),
                      },
                    ]}
                  >
                    <Input
                      placeholder="Key"
                      onFocus={(e) => (e.target.style.backgroundColor = "#fff")}
                      onBlur={(e) => (e.target.style.backgroundColor = "#fff")}
                      onMouseOver={(e) =>
                        (e.target.style.backgroundColor = "#fff")
                      } //
                      style={{
                        backgroundColor: "#fff!important",
                        color: "#000 ",
                        marginTop: "1rem",
                      }}
                    />
                  </Form.Item>
                </div>
                <Form.Item shouldUpdate>
                  <div
                    className="coupon-activation-activate-btn-mac"
                    onClick={() => {
                      if (macIsValid) {
                        setVisible(true);
                        form.submit();
                      }
                    }}
                  >
                    <span> Login</span>
                  </div>
                </Form.Item>
              </>
            </Form>

            <ErrorModal
              message={errorMessage}
              visible={errorMessage ? true : false}
              onClose={() => {
                setErrorMessage(null);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
