import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import { useDispatch, useSelector } from "react-redux";

import { Button, Modal } from "antd";

import useTranslation from "../../hooks/useTranslation";

import { actions } from "../../store";

import REQUESTS from "../../api/requests";

import Languages from "./Languages";

import TEXT from "../../config/text";
import PATHS from "../../config/paths";
import { RESELLER_HOST } from "../../config";

import GlobalEditor from "../../editors/GlobalEditor";

import style from "../style/navbar.module.scss";
import Logo from "../../assets/org.png";

export default function Navbar() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const { setAppInfoInRedux } = bindActionCreators(actions, dispatch);

  const { webSettings } = useSelector((store) => store.globalState);

  const translation = useTranslation();

  const { isAdmin } = useSelector((store) => store.globalState);

  const { widgets } = useSelector((state) => state.globalState);

  const [underConstruction, setUnderConstruction] = useState(false);

  const [modal, contextHolder] = Modal.useModal();

  const handleText = (page, text) => {
    if (translation?.pages?.[page]?.[text]) {
      return translation?.pages?.[page]?.[text];
    }

    return TEXT?.pages?.[page]?.[text];
  };

  const logout = () => {
    const TOKEN = localStorage.getItem("TOKEN");

    modal.confirm({
      title: null,
      icon: null,
      content: "Do you want to logout ?",
      okText: "LOG OUT",
      cancelText: "CANCEL",
      onOk: () => {
        if (TOKEN) {
          localStorage.removeItem("TOKEN");
          navigate(PATHS.LOGIN);
        }
      },
      className: "ant-logout-confirm",
    });
  };

  const getAppInfo = () => {
    function callback(data) {
      setAppInfoInRedux(data);

      if (data.under_construction) {
        navigate("/under_construction");
        setUnderConstruction(data.under_construction);
      }
    }

    function errorCallback(err) {
      navigate("/under_construction");
      window.location.href = "/#/under_construction";
    }

    REQUESTS.APP_INFO(callback, errorCallback);
  };

  useEffect(() => {
    if (underConstruction && location.pathname !== "/under_construction") {
      navigate("/under_construction");
    }
  }, [location, underConstruction]);

  useEffect(() => {
    getAppInfo();
  }, []);

  return (
    <>
      <div className={style.container}>
        <NavLink to={PATHS.HOME}>
          <img
            src={Logo}
            alt="brand-logo"
            loading="lazy"
            className={style.logo}
            style={{ opacity: webSettings?.globals.logo ? 1 : 0 }}
          />
        </NavLink>
        <div className={style.routes}>
          <NavLink
            to={PATHS.HOME}
            className={({ isActive }) =>
              isActive
                ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                : style["menu-list__item__link"]
            }
          >
            {handleText("home", "page_name")}
          </NavLink>
          {/* new */}
          <NavLink
            to={PATHS.MANAGE_DEVICES}
            className={({ isActive }) =>
              isActive
                ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                : style["menu-list__item__link"]
            }
            onClick={(event) => {
              event.preventDefault();
              const JWT = localStorage.getItem("TOKEN");
              if (JWT) {
                navigate(PATHS.MANAGE_TABS);
              } else {
                navigate(PATHS.MANAGE_DEVICES);
              }
            }}
          >
            {handleText("manage_devices", "page_name")}
          </NavLink>
          {widgets?.referral?.enabled === "true" &&
            widgets?.reseller?.enabled === "true" && (
              <NavLink
                to={RESELLER_HOST + "#/login"}
                target="_blank"
                rel="noreferrer"
                className={({ isActive }) =>
                  isActive
                    ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                    : style["menu-list__item__link"]
                }
              >
                {handleText("reseller", "page_name")}
              </NavLink>
            )}

          {widgets?.news?.enabled === "true" && (
            <NavLink
              to={PATHS.NEWS}
              className={({ isActive }) =>
                isActive
                  ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                  : style["menu-list__item__link"]
              }
            >
              {handleText("news", "page_name")}
            </NavLink>
          )}

          {widgets?.pricing?.enabled === "true" && (
            <NavLink
              to={PATHS.PRICING}
              className={({ isActive }) =>
                isActive
                  ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                  : style["menu-list__item__link"]
              }
            >
              {handleText("pricing", "page_name")}
            </NavLink>
          )}

          {widgets?.support?.enabled === "true" && (
            <NavLink
              to={PATHS.CONTACT_US}
              className={({ isActive }) =>
                isActive
                  ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                  : style["menu-list__item__link"]
              }
            >
              {handleText("contact_us", "page_name")}
            </NavLink>
          )}

          {widgets &&
            widgets.installation_guide &&
            widgets.installation_guide.enabled &&
            widgets.installation_guide.enabled === "true" && (
              <NavLink
                to={PATHS.INTALLATION_GUIDE}
                className={({ isActive }) =>
                  isActive
                    ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                    : style["menu-list__item__link"]
                }
              >
                {handleText("installation_guide", "page_name")}
              </NavLink>
            )}

          {isAdmin && (
            <NavLink
              to={PATHS.CHAT}
              className={({ isActive }) =>
                isActive
                  ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                  : style["menu-list__item__link"]
              }
            >
              {handleText("chat", "page_name")}
            </NavLink>
          )}
        </div>
      </div>

      <div className={style["navbar-login-lang-section"]}>
        {(window.location.hash === "#/playlists" ||
          window.location.hash === "#/manage-playlists/profile") && (
          <Button className={style["btn"]} onClick={logout}>
            {handleText("playlist", "logout_button_text")}
          </Button>
        )}
        <Languages />
      </div>

      {isAdmin && <GlobalEditor />}

      {contextHolder}
    </>
  );
}
