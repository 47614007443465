import React, { useEffect } from "react";

import "./_referring-pages.scss";

import TEXT from "../../config/text";

import useTranslation from "../../hooks/useTranslation";
import termsImg from "./terms.gif";

export default function TermsOfUse() {
  const translation = useTranslation();

  const handleText = (text) => {
    if (translation?.pages?.terms_of_online_sale?.[text]) {
      return translation?.pages?.terms_of_online_sale?.[text];
    }
    return TEXT?.pages?.terms_of_online_sale?.[text];
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  return (
    <div className="referring-pages">
      <div
        style={{
          marginTop: "30px",
          paddingTop: 30,
        }}
        className="terms-of-use"
      >
        <h1>Terms Of Use</h1>

        <div>
          <p>
            The Home Player application is delivered without any content. In
            addition, no content is provided by the application even after
            activation on our website. The information displayed at the
            beginning includes the MAC address as well as the website so that
            clients can activate the application once for life. The Home Player
            app has a free 14-day trial period once installed on your Smart TV
            to test the features of the app. The MAC address and the URL are
            essential for the proper functioning of the application.
          </p>
        </div>
        <div>
          {/* <h1>1. Preamble</h1> */}
          <p>
            Home Player does not provide any content or subscription. Any
            content or subscriptions provided under the Home Player name are
            fraudulent and not associated with our services. Home Player
            disclaims any responsibility for the use of fraudulent content. The
            application Home Player and its representatives cannot be held
            responsible for the quality or interruptions of the content used
            with Home Player. The app only provides a media player platform.
          </p>
          {/* <h1>2. Purpose</h1> */}
          <p>
            Home Player does not provide advertising or promotions for content
            providers or subscriptions and reserves the right to take legal
            action against anyone using our name or logo for advertising
            purposes.
          </p>
        </div>
        <div>
          {/* <h1>3. Sales</h1> */}
          <p>
            After accepting the above conditions, no refund will be granted
            under any circumstances.
          </p>
        </div>
        <div>
          {/* <h1>4. Payment Methods</h1> */}

          <p>
            Note: If you are using a stolen card, your MAC address will be
            banned and its IP address will be reported.
          </p>
        </div>
        <div>
          {/* <h1>5. Data Privacy</h1> */}
          <p>
            Home Player is owned and operated by homeplayer.app, which reserves
            the right to restrict access to the app, change its pricing, and
            modify these Terms of Use at any time without the consent of its
            users. It is the user’s responsibility to stay up to date with the
            terms.
          </p>
        </div>
      </div>
      <div className="img-holder">
        <img src={termsImg} alt="side img" />
      </div>
    </div>
  );
}
