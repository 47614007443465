// src\pages\contact\ContactUs.jsx
import { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { Form, Input } from "antd";

import useTranslation from "../../hooks/useTranslation";

import LottiePlayer from "react-lottie-player";

import ContactUsInfo from "./ContactUsInfo";

import TEXT from "../../config/text";

import REQUESTS from "../../api/requests";

import ContactUsEditor from "../../editors/ContactUsEditor";

import ButtonComponent from "../../components/ButtonComponent";

import contactUsImg from "./contact-us.json";

import style from "./style/contact.module.scss";
import SuccessModal from "../../components/SuccessModal";

export default function ContactUs() {
  const translation = useTranslation();

  const { webSettings, appInfo } = useSelector((state) => state.globalState);

  const isAdmin = useSelector((store) => store.globalState.isAdmin);

  const [isSeneding, setIsSending] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);

  const [form] = Form.useForm();

  const handleText = (text) => {
    if (translation?.pages?.contact_us?.[text]) {
      return translation?.pages?.contact_us?.[text];
    }

    return TEXT?.pages?.contact_us?.[text];
  };

  const onFinish = (values) => {
    setIsSending(true);

    const body = {
      title: values.title,
      email: values.email,
      message: values.message,
    };

    function callback() {
      setIsSending(false);
      setSuccessMessage(handleText("submition_success_message"));
      form.resetFields();
    }

    function errorCallback() {
      setIsSending(false);
      form.resetFields();
    }

    REQUESTS.SEND_EMAIL(body, callback, errorCallback);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  const pageStyle = {
    backgroundImage: `url(${webSettings?.pages?.contact_us?.background_image})`,
    backgroundColor: webSettings?.pages?.contact_us?.background_color,
    backgroundSize: "cover",
  };

  return (
    <div className={style["contact-us_main"]} style={pageStyle}>
      {isAdmin && <ContactUsEditor />}
      <div className="container-contact">
        <div className={style["contact-us_wrapper"]}>
          <div className={style["info-form_wrapper"]}>
            <ContactUsInfo />
            <div className={style["form-image-container"]}>
              <Form className={"contact-form"} form={form} onFinish={onFinish}>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: handleText("title_input_message"),
                    },
                  ]}
                >
                  <div
                    style={{
                      border: "1px solid rgba(7, 64, 76, 0.3)",
                      borderRadius: 5,
                    }}
                  >
                    <Input
                      className="title-input"
                      placeholder={handleText("title_input_placeholder")}
                    />
                  </div>
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: handleText("email_input_message"),
                    },
                  ]}
                >
                  <div
                    style={{
                      border: "1px solid rgba(7, 64, 76, 0.3)",
                      borderRadius: 5,
                    }}
                  >
                    <Input
                      className="title-input"
                      placeholder={handleText("email_input_placeholder")}
                    />
                  </div>
                </Form.Item>

                <Form.Item
                  name="message"
                  rules={[
                    {
                      required: true,

                      message: handleText("message_input_message"),
                    },
                  ]}
                >
                  <div
                    style={{
                      border: "1px solid rgba(7, 64, 76, 0.3)",
                      borderRadius: 5,
                    }}
                  >
                    <Input.TextArea
                      className="title-input"
                      rows={4}
                      placeholder="mac address .. phone number .. your message"
                      style={{ resize: "none" }}
                    />
                  </div>
                </Form.Item>

                <Form.Item shouldUpdate>
                  <ButtonComponent
                    loading={isSeneding}
                    title={handleText("button_title")}
                    onClick={() => form.submit()}
                    size="large"
                  />
                </Form.Item>
              </Form>
              <div className={style["contact-image-container"]}>
                <LottiePlayer
                  loop
                  animationData={contactUsImg}
                  play
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "1rem",
                  }}
                />
              </div>
            </div>
            <div className={style["info-div"]}>
              <ul
                className={style["contact-list"]}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <a
                  href={`tel:+1 586 765-2233`}
                  target="_blank"
                  style={{ marginRight: "2rem" }}
                >
                  <div className={style["list-info"]}>
                    <p>+1 586 765-2233</p>
                  </div>
                </a>

                <a href={`mailto:${appInfo?.email}`} target="_blank">
                  <div className={style["list-info"]}>
                    <p>{appInfo?.email}</p>
                  </div>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        message={successMessage}
        visible={successMessage ? true : false}
        onClose={() => {
          setSuccessMessage(null);
        }}
      />
    </div>
  );
}
