export const setAppInfoInRedux = (appInfo) => {
  return (dispatch) => {
    dispatch({
      type: "appInfo",
      payload: appInfo,
    });
  };
};

export const setAdmin = (isAdmin) => {
  return (dispatch) => {
    dispatch({
      type: "isAdmin",
      payload: isAdmin,
    });
  };
};

export const setWidgets = (widgets) => {
  return (dispatch) => {
    dispatch({
      type: "widgets",
      payload: widgets,
    });
  };
};

export const setWebSettings = (settings) => {
  return (dispatch) => {
    dispatch({
      type: "webSettings",
      payload: settings,
    });
  };
};
// new
export const setDeviceFromStore = (device) => {
  return (dispatch) => {
    dispatch({
      type: "deviceFromStore",
      payload: device,
    });
  };
};

export const setSelectedLanguage = (lang) => {
  return (dispatch) => {
    dispatch({
      type: "selectedLanguage",
      payload: lang,
    });
  };
};
