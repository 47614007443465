import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element, redirectTo, checkToken }) => {
  const token = localStorage.getItem("TOKEN");

  if (checkToken && !token) {
    return <Navigate to={redirectTo} />;
  }

  if (!checkToken && token) {
    return <Navigate to={redirectTo} />;
  }

  return element;
};

export default ProtectedRoute;
