import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Playlist from "../playlists/PlaylistsPage";
import ActivationPage from "../activationPage/ActivationPage";
import style from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import PATHS from "../../config/paths";
import TransferMacActivation from "../transferMacActivation/TransferMacActivation";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function ManageDevicesTabs() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const logout = () => {
    const TOKEN = localStorage.getItem("TOKEN");
    if (TOKEN) {
      localStorage.removeItem("TOKEN");
      navigate(PATHS.MANAGE_DEVICES);
    }
  };

  const handleChange = (event, newValue) => {
    if (newValue === 3) {
      logout();
    } else {
      setValue(newValue);
    }
  };

  return (
    <div className={style["manage-page"]}>
      <div className={style["box-container"]}>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            // height: 350,
            borderRadius: "2rem",
            opacity: "0.9",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tab label="Playlists" {...a11yProps(0)} />
            <Tab label="Activate device" {...a11yProps(1)} />
            <Tab label="Transfer activation" {...a11yProps(2)} />
            <Tab label="logout" {...a11yProps(3)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Playlist />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ActivationPage />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TransferMacActivation />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}
